






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      tableOptions: null,
      userManager,
    };
  },
  methods: {
    makeTableOptions(country) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "time",
          "sort-desc": true,
        },
        content: {
          name: "Import - Return Scan History",
          urlEnabled: true,
          search: {},
          displayFields: {
            time: {
              text: "Return time",
              sortableable: true,
              options: {
                filter: "dateTime",
              },
            },
            warehouse: {
              text: "Warehouse",
              sortable: true,
              options: {
                subProp: "order.warehouse.name",
              },
            },
            market: {
              text: "Market",
              sortable: true,
              options: {
                subProp: "market.name",
              },
            },
            shippingCompany: {
              text: "Shipping company",
              options: {
                subProp: "shippingCompany.name",
              },
            },
            transportTrackingNumber: {
              text: "Tracking number",
            },
            orderId: {
              text: "Order ID",
              options: {
                subProp: "order.orderId",
              },
            },
            productItemSkus: {
              text: "SKU",
              sortable: true,
              options: {
                labels(_, item) {
                  return item.productItems.map((productItem) => productItem.product.sku);
                },
                labelsSeparatedByNewLine: true,
              },
            },
            productItemCounts: {
              text: "Count",
              options: {
                labels(_, item) {
                  return item.productItems.map((productItem) => productItem.count);
                },
                labelsSeparatedByNewLine: true,
              },
            },
            productItemRealCounts: {
              text: "Actual count",
              options: {
                labels(_, item) {
                  return item.productItems.map((productItem) => productItem.realCount);
                },
                labelsSeparatedByNewLine: true,
              },
            },
            productItemFailedCounts: {
              text: "Damaged count",
              options: {
                labels(_, item) {
                  return item.productItems.map((productItem) => productItem.failedCount);
                },
                labelsSeparatedByNewLine: true,
              },
            },
            productItemSwitchedCounts: {
              text: "Switched count",
              options: {
                labels(_, item) {
                  return item.productItems.map((productItem) => productItem.switchedCount);
                },
                labelsSeparatedByNewLine: true,
              },
            },
            productItemLostCounts: {
              text: "Not received",
              options: {
                labels(_, item) {
                  return item.productItems.map((productItem) => productItem.count - productItem.realCount - (productItem.failedCount || 0));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            user: {
              text: "User account",
              options: {
                subProp: "user.name",
              },
            },
            action: {},
          },
          filters: {
            filters: {
              time: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Time (start)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Time (end)",
                    },
                  },
                },
              },
              "productItems.productId": {
                type: "XAutocomplete",
                attrs: {
                  label: "SKU",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          topActionButtons: {
            export: {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Export Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("trackingNumberScanItems", `@/export`, findAllOptions);
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "countryId",
              operator: "equal_to",
              value: country._id,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("trackingNumberScanItems", "findAll", options);
              return [items, count];
            },
            async delete(item) {
              return await coreApiClient.call("trackingNumberScanItems", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "_id",
            },
          },
        },
      };
    },
  },
  async created() {
    const country = await coreApiClient.call("countries", "findById", {
      id: this.$route.params.countryId,
    });
    this.tableOptions = this.makeTableOptions(country);
  },
});
